import { useExperiments } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import type { FC } from 'react';
import React from 'react';

import { Experiment } from '../../../../constants/experiments';
import type { BaseComponentProps } from '../../../../types';
import { ProfileLayout } from '../../../../types';

const OldDesktopResponsiveWidget: FC<BaseComponentProps> = loadable(() => {
  return import(
    /* webpackChunkName: "DesktopResponsiveRootWidget" */ './OldDesktopResponsiveWidget'
  );
});

const DesktopResponsiveWidget: FC<BaseComponentProps> = loadable(() => {
  return import(
    /* webpackChunkName: "DesktopResponsiveRootWidget" */ './DesktopResponsiveWidget'
  );
});

const DesktopNonResponsiveWidget: FC<BaseComponentProps> = loadable(() => {
  return import(
    /* webpackChunkName: "DesktopNonResponsiveRootWidget" */ './DesktopNonResponsiveWidget'
  );
});

const DesktopWidget: FC<BaseComponentProps> = (props) => {
  const { experiments } = useExperiments();

  const renderResponsiveWidget =
    props.computed.profileLayout === ProfileLayout.FullWidth;

  if (renderResponsiveWidget) {
    const withProfileAlignmentCssVars = experiments.enabled(
      Experiment.EnableProfileAlignmentCssVars,
    );

    return withProfileAlignmentCssVars ? (
      <DesktopResponsiveWidget {...props} />
    ) : (
      <OldDesktopResponsiveWidget {...props} />
    );
  }

  return <DesktopNonResponsiveWidget {...props} />;
};

export default DesktopWidget;
